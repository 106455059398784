<template>
  <div class="home">
    <img alt="INFOCAFE" src="../assets/logo.png" width="20%">
  </div>
</template>

<script>
// @ is an alias to /src
import { mapActions, mapState } from "vuex";
import axios from "axios";
export default {
  name: 'home',

  computed: {
    ...mapState(["db","headers_db"]),
  },
  methods: {
    ...mapActions(["validateSession"]),
    async getProcesses(){
        await axios.get(this.db + 'types',
        {
          headers: this.headers_db
        }).then()
        .catch((error) => {
          this.loading = false;
          this.validateSession(error).then((resp) => { if(resp) this.getProcesses(); })
        });
      },
  },
  created() {
    this.getProcesses();
  }
}
</script>


<style>
  .home {
      display: flex;
      align-items: center;
      justify-content: center;
  }
</style>